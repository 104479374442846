import { HeightEqualizer } from "./classes/HeightEqualizer"
import Rails from '@rails/ujs'

export function startRails() {
  Rails.start()
}

// Freezes the background, so the user can't interact with it.
export function freezeBackground() {
  window.freezeBackground = () => { document.querySelector("html").style.cssText += "overflow: hidden;" }

  window.freezeBackground()
}

// Unfreezes the backgroud so the user can interact with it again
export function unfreezeBackground() {
  window.unfreezeBackground = () => { document.querySelector("html").style.cssText += "overflow: auto;" }

  window.unfreezeBackground()
}

export function initHeightEqualizer() {
  const tabs = document.querySelectorAll("button[data-bs-toggle='tab']")
  
  new HeightEqualizer()

  for (let tab of tabs) {
    tab.addEventListener("shown.bs.tab", function () {
      new HeightEqualizer(`.equal-height${tab.getAttribute("data-bs-target")}`)
    }, { once: true })
  }
}

export function initScrollTop() {
  const scrollUp = document.querySelector('.footer__scroll-top')

  if (scrollUp) {
    scrollUp.addEventListener('click', (e) => {
      e.preventDefault()

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }
}
