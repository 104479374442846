// Opens and closes the catalog filters on mobile devices
export function catalogFilters() {
  window.catalogFilters = function () {
    if (window.innerWidth < 1200) {
      document.querySelectorAll(".catalog__filters-select > span").forEach(catalogFilter => {

        catalogFilter.addEventListener("click", function (e) {
          e.preventDefault()

          document.querySelector(".catalog__filters").classList.add("catalog__filters--mobile")
          document.querySelector(".catalog__filters").classList.remove("d-none")
          window.freezeBackground()
        })
      })

      document.querySelectorAll(".close-filters").forEach(closeFilter => {
        closeFilter.addEventListener("click", function (e) {
          e.preventDefault()

          document.querySelector(".catalog__filters").classList.remove("catalog__filters--mobile")
          document.querySelector(".catalog__filters").classList.add("d-none")
          window.unfreezeBackground()
        })
      })
    }
  }
}

export function initFiltersToggle() {
  window.initFiltersToggle = function () {
    const filtersToggle = document.querySelectorAll(".filter-toggle")

    for (let filterToggle of filtersToggle) {
      filterToggle.addEventListener("click", () => {

        if (filterToggle.classList.contains("filter-toggle--closed")) {
          filterToggle.classList.remove("filter-toggle--closed")
        } else {
          filterToggle.classList.add("filter-toggle--closed")
        }
      })
    }
  }
}